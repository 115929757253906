@font-face {
  font-family: SeatBcn-Medium;
  src: url("./seat/assets/fonts/SeatBcn-Medium.otf");
}

@font-face {
  font-family: SKODANext-Regular;
  src: url("./skoda/assets/fonts/SKODANext-Regular.otf");
}

@font-face {
  font-family: AudiTypeV01-ExtendedBold;
  src: url("./audi/assets/fonts/AudiTypeV01-ExtendedBold.otf");
}

@font-face {
  font-family: VWHead-ExtraBold;
  src: url("./volkswagen/assets/fonts/VWHead-ExtraBold.otf");
}
